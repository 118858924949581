import React, { useState } from 'react'
import Layout from '../../components/layout'
import { Container, Row, Col, Modal, ModalBody, } from 'reactstrap'
import styled from 'styled-components'
import Certificate1 from '../../images/cert/c1.png'
import Certificate2 from '../../images/cert/c2.png'
import Certificate3 from '../../images/cert/c3.png'
import Certificate4 from '../../images/cert/c4.png'
import Certificate5 from '../../images/cert/c5.png'
import Certificate6 from '../../images/cert/c6.png'
import { graphql, StaticQuery } from 'gatsby'
import Img from "gatsby-image"
import BlogForm from './Blog/BlogForm'

const Certifications = (props) => {
    const [modal, setModal] = useState(false);

    const toggle = () => setModal(!modal);

    return (
        <StaticQuery
            query={graphql`
      query {
        Banner: file(relativePath: { eq: "certifications_banner1.jpg" }) {
            childImageSharp {
              fluid(quality: 90, maxWidth: 1220, maxHeight:400,) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          } 
        
      }
    `}

            render={data => {
                const imageData = data.Banner.childImageSharp.fluid
                const { className } = props;
                return (
                    <Layout path="/certifications/">
                        <div className="banner_section">
                            <Img
                                // Tag="section"
                                fluid={imageData}
                                className="startup_background"
                            ></Img>
                            <div className="startup">
                                <div className="startup_center">
                                </div>
                            </div>
                        </div>
                        <section className="certificate_section">
                            <Container>
                                <div className="text_center">
                                    <h3>Certifications and Partners</h3>
                                </div>
                                <Row>
                                    <Col md="6">
                                        <div className="certificate_card_border">
                                            <div className="certificate_card">
                                                <img src={Certificate1} alt="img" />
                                            </div>
                                            <div>
                                                <p>We are a certified minority owned enterprise with WRMSDC and also a committee member of MBE – Minority Business Enterprise Input Committee.</p>
                                                <p>WRMSDC supports the growth and welfare of minority communities by championing the use of minority-owned businesses in Northern California, Nevada, and Hawai‘i. We advance business opportunities for certified minority business enterprises and connect them to Corporate Members. Our parent organization, the National Minority Supplier Development Council, helps over 12,000 minority businesses connect with major Fortune 500 corporations and their buyers every year.</p>
                                            </div>
                                        </div>
                                    </Col>

                                    <Col md="6">
                                        <div className="certificate_card_border">
                                            <div className="certificate_card">
                                                <img src={Certificate2} alt="img" />
                                            </div>
                                            <div>
                                                <p>TRIDENT is proud to be a certified WBENC MEMBER.</p>
                                                <p>The Women’s Business Enterprise National Council (WBENC) is the largest certifier of women-owned businesses in the U.S. and a leading advocate for women business owners and entrepreneurs.<br />
                                                    We believe diversity promotes innovation, opens doors, and creates partnerships that fuel the economy. That’s why we provide the most relied upon certification standard for women-owned businesses and the tools to help them succeed</p>
                                            </div>
                                            <br />
                                            <br />
                                            <br />
                                        </div>
                                    </Col>
                                    <Col md="6">
                                        <div className="certificate_card_border">
                                            <div className="certificate_card">
                                                <img src={Certificate3} alt="img" />
                                            </div>
                                            <div>
                                                {/* <p>
                                                Trident is a proud member and partner of TechServe alliance. 
                                                TechServe Alliance is the premier IT & engineering staffing association dedicated to advancing excellence and ethics within the IT & engineering staffing and solutions industry.</p> */}
                                                <p>TechServe Alliance is the premier IT & engineering staffing association dedicated to advancing excellence and ethics within the IT & engineering staffing and solutions industry.  TechServe Alliance helps our leadership to be informed, engaged and connected. TechServe Alliance helps members with collaborative networking and knowledge sharing, operational and market trends and guides us in our commitment to fair business practices and high ethical code of conduct standards.</p>
                                                {/* <p>Hundreds of IT & engineering staffing and solutions firms and thousands of affiliated professionals, count on TechServe Alliance to keep their leadership informed, engaged and connected.</p> */}
                                                {/* <ul>
                                                <li>Collaborative networking and knowledge-sharing for the collective benefit of all</li>
                                                <li>Keen understanding of <a href="https://www.techservealliance.org/Research/Operational-Benchmarking" target="blank">operational and market trends</a> that impact their performance</li>
                                                <li>Commitment to fair business practices and high ethical <a href="https://www.techservealliance.org/About/About-TechServe-Alliance/Statement-of-Business-Principles" target="blank"> code of conduct</a> standards</li>
                                            </ul> */}
                                                <br />

                                            </div>
                                        </div>
                                    </Col>
                                    <Col md="6">
                                        <div className="certificate_card_border">
                                            <div className="certificate_card">
                                                <img src={Certificate4} alt="img" />
                                            </div>
                                            <div>
                                                <p>E-Verify is a web-based system that allows enrolled employers to confirm the eligibility of their employees to work in the United States.</p>
                                                <p> E-Verify employers verify the identity and employment eligibility of newly hired employees by electronically matching information provided by employees on the Form I-9, Employment Eligibility Verification, against records available to the Social Security Administration (SSA) and the Department of Homeland Security (DHS).</p>
                                            </div><br />
                                        </div>
                                    </Col>
                                    <Col md="6">
                                        <div className="certificate_card_border">
                                            <div className="certificate_card">
                                                <img src={Certificate5} alt="img" />
                                            </div>
                                            <div>
                                                <p>TRIDENT is an active member of ITServe including an active participant of the bay area chapter of ITServe.</p>
                                                <p>ITServe Alliance is a largest association of IT Services organizations. The alliance is the voice of all prestigious IT companies functioning with similar interests across United States. Through the years ITserve has evolved as a resourceful and respected platform to collaborate and initiate measures in the direction of protecting common interests and ensuring collective success.</p>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md="6">
                                        <div className="certificate_card_border">
                                            <div className="certificate_card">
                                                <img src={Certificate6} alt="img" />
                                            </div>
                                            <div>
                                                <p>JobDiva is the leading global applicant tracking system. and talent managemensolution.</p>
                                                <p>Trident has been using JobDiva as its ATS for the past five years and we have an active database of over 2 Million resumes. We use JobDiva to harvest around thousand latest resumes into our database every single day. It has been the most effective way of tracking our jobs and applications so that it becomes a seamless process for both the candidates and our recruiters.</p>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </section>
                        <section className="footer_section">
                            <Container>
                                <Row>
                                    <Col md="12">
                                        <h1>Find out what Trident can do for you.</h1>
                                        <div className="footer_button">
                                            <a href onClick={toggle}>Contact Us</a>
                                        </div>
                                        <Modal isOpen={modal} toggle={toggle} className={className} className="form_modal">
                                            <ModalBody >
                                                <BlogForm title="Find out what Trident can do for you." close={()=> toggle()}/>
                                            </ModalBody>
                                        </Modal>
                                    </Col>
                                </Row>
                            </Container>
                        </section>
                        {/* <BlogForm refItem={() => { }} title="Find out what Trident can do for you." redirectTitle="Certification" pathname={props.location.pathname} /> */}

                    </Layout>
                )
            }}
        />
    )
}
const StyledBackgroundSection = styled(Certifications)`
  width: 100%;
  background-position: bottom center;
  background-repeat: repeat-y;
  background-size: cover;
  margin: 0;
  background-color: inherit !important;
  
  `

export default StyledBackgroundSection